<template>
  <v-card
    v-if="answer"
    flat
    outlined
  >
    <v-card-text>
      {{ label }}
      <v-btn-toggle
        v-model="answer.manual_score"
        :mandatory="false"
        @change="save"
        dense
      >
        <v-btn
          v-for="(grade, index) in grades"
          :value="grade"
          :key="index"
          :class="{
            'ai-suggestion': grade === gradeSuggestion,
          }"
        >
          {{ displayGrade(grade) }}
        </v-btn>
        <v-btn :value="customGrade != null && customGrade">
          <v-text-field
            flat
            v-model="customGrade"
            @click.stop
            @change="saveCustomGrade"
            hide-details
            class="input-in-btn pt-1 pb-2 no-border"
            type="number"
            :placeholder="
              $t(
                'evalmee.home.teacher.openQuestionGrader.customGrade.placerholder'
              )
            "
            :rules="gradesRules()"
            :v-tooltip="
              $t(
                'evalmee.home.teacher.openQuestionGrader.customGrade.tooltip',
                { maxGrade }
              )
            "
            ref="customGrade"
            dense
          />
        </v-btn>
      </v-btn-toggle>

      <v-icon
        color="green"
        class="fixed_width_icon"
      >
        {{ toGrade ? "" : "check" }}
      </v-icon>


      <div
        v-if="answerFeedback?.grade_by_criterion && answerFeedback.grade_by_criterion.length > 0"
        class="mt-4"
      >
        <v-list-item
          v-for="criterion in answerFeedback.grade_by_criterion"
          :key="criterion.uuid"
          class="mb-2 px-0"
          dense
          v-tooltip.left="$t('evalmee.home.teacher.openQuestionGrader.gradingCriteriaSuggestion.hiddenTooltip')"
        >
          <v-list-item-content>
            <v-list-item-title class="d-flex">
              {{ criterion.name }}
              <v-spacer />
              <chip-points
                :score="criterion.grade * question.attributes.coefficient * criterion.weight / 100"
                :coefficient="criterion.weight * question.attributes.coefficient / 100"
                icon="mdi-creation-outline"
              />
            </v-list-item-title>
            <span class="grey--text font-weight-medium">
              {{ criterion.comment }}
            </span>
          </v-list-item-content>
          <v-list-item-action-text />
        </v-list-item>
      </div>
    </v-card-text>

    <turnitin-answer-report
      v-if="quiz.similarity_tool_enabled"
      class="mt-4"
      :answer="answer"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import TurnitinAnswerReport from "./similarity_check/turnitin_answer_report.vue"
import getFeatureMixin from "@/components/shared/get_feature_mixin"
import ChipPoints from "@/components/questions/chip_points.vue"

export default {
  name: "OpenQuestionGrader",
  components: { ChipPoints, TurnitinAnswerReport },
  mixins: [getFeatureMixin],
  props: {
    answer: { type: Object, required: true },
    label: { type: String, default: null },
    quiz: { type: Object, required: true },
    question: { type: Object, required: true },
  },
  data: () => ({
    comment: "",
    customGrade: null,
    gradeSuggestion: null,
  }),
  computed: {
    ...mapGetters(["currentUser", "answersFeedbackByAnswerId", "questionById"]),
    toGrade() {
      if (this.answer.score === this.customGrade && this.answer.score != null)
        return false
      if (
        parseFloat(this.customGrade) > 0 &&
        !this.validCustomGrade(this.customGrade)
      )
        return true

      return this.answer?.require_manual_grading
    },
    grades() {
      const scale = this.gradeScales.find((gs) => gs.maxGrade === this.maxGrade)?.scale ||
        this.defaultScale

      if(this.gradeSuggestion && !scale.includes(this.gradeSuggestion)) {
        scale.push(this.gradeSuggestion)
      }
      return scale.sort((a, b) => a - b)
    },
    gradeScales() {
      return [
        {
          maxGrade: 1,
          scale: [...this.wrongAnswerScore, 0, 0.25, 0.5, 0.75, 1],
        },
        {
          maxGrade: 1.5,
          scale: [...this.wrongAnswerScore, 0, 0.5, 1, 1.5],
        },
        {
          maxGrade: 2,
          scale: [...this.wrongAnswerScore, 0, 0.5, 1, 1.5, 2],
        },
        {
          maxGrade: 3,
          scale: [...this.wrongAnswerScore, 0, 1, 2, 3],
        },
        {
          maxGrade: 4,
          scale: [...this.wrongAnswerScore, 0, 1, 2, 3, 4],
        },
        {
          maxGrade: 5,
          scale: [...this.wrongAnswerScore, 0, 1, 2, 3, 4, 5],
        },
        {
          maxGrade: 6,
          scale: [...this.wrongAnswerScore, 0, 2, 4, 6],
        },
      ]
    },
    defaultScale() {
      return [
        ...this.wrongAnswerScore,
        0,
        Math.round((this.maxGrade * 100) / 2) / 100,
        this.maxGrade,
      ]
    },
    maxGrade() {
      return this.questionWithCorrection?.coefficient || 0
    },
    questionWithCorrection() {
      return this.questionById(this.question.id)
    },
    wrongAnswerScore() {
      if (this.quiz?.score_for_wrong_answer == null) return []
      if (this.quiz?.score_for_wrong_answer === 0) return []

      return [this.quiz.score_for_wrong_answer]
    },
    answerFeedback() {
      return this.answersFeedbackByAnswerId(this.answer.id)
    },
  },
  methods: {
    ...mapActions(["updateAnswer", "newAppNotification"]),
    save() {
      this.answer.comment = this.comment
      this.updateAnswer(this.answer)
        .then(() =>
          this.newAppNotification({
            message: this.$t(
              "evalmee.home.teacher.openQuestionGrader.toast.gradeSaved"
            ),
            type: "success",
          })
        )
        .catch((error) =>
          this.newAppNotification({
            message: this.$t(
              "evalmee.home.teacher.openQuestionGrader.toast.invalidGrade",
              { error }
            ),
            type: "error",
          })
        )
    },
    saveCustomGrade() {
      this.answer.manual_score = this.customGrade
      this.save()
    },
    updateCustomGrade() {
      if (this.grades.includes(this.answer.manual_score)) {
        this.customGrade = null
        this.$refs.customGrade?.validate()
        this.$refs.customGrade?.blur()
        return
      }

      this.customGrade = this.answer.manual_score
    },
    validCustomGrade(grade) {
      return this.gradesRules()
        .map((rule) => rule(grade) === true)
        .reduce(Boolean)
    },
    displayGrade(grade) {
      return `${grade}`.replace(".", ",")
    },
    gradeValidator(grade) {
      if ((parseFloat(grade) || 0) <= this.maxGrade) return true

      return this.$t(
        "evalmee.home.teacher.openQuestionGrader.addComment.errors.maxGrade",
        { maxGrade: this.maxGrade }
      )
    },
    gradesRules() {
      return [this.gradeValidator]
    },
  },
  watch: {
    "answerFeedback.user_content": {
      handler(val) {
        this.comment = val
        this.updateCustomGrade()
      },
      immediate: true,
    },
    "answerFeedback.grade_suggestion": {
      handler(val) {
        this.gradeSuggestion = val
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
.fixed_width_icon {
  width: 24px;
}

.input-in-btn {
  width: 60px;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn.ai-suggestion {
  border-width: 3px !important;
  background:
    radial-gradient(ellipse, rgb(245, 245, 245, 1) 50%, rgb(245, 245, 245, 0.7)) padding-box,
    linear-gradient(
        var(--angle),
        #ff82f4 var(--light-size),
        #ff4ff3,
        #7678ff ,
        #051eff
      ) border-box;
  animation: 8s rotate linear infinite,
             2s expend-light linear infinite;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}
@keyframes expend-light {
  0%  {--light-size: 0%;}
  50% {--light-size: 50%;}
  100%{--light-size: 0%;}
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@property --light-size {
  syntax: "<percentage>";
  initial-value: 1%;
  inherits: false;
}
</style>
